<template>
  <v-container
      fluid
      grid-list-xl
      fill-height>
    <v-layout
        justify-center
        align-center
    >

      <template v-if="loaded">
        <data-table ref="datatable"
                    :api-end-point="apiurl"
                    :search="search"
                    :sort-order="sortOrder"
                    :table-name="'Notification Logs'"
                    :isDetail="false"
                    :isExport="false"
                    :pre-selected-filters="filters.selectedFilters"
        >
          <template slot="filters">
            <filters :filters="filters.typeFilters"></filters>
            <filters :filters="filters.subTypeFilters"></filters>
          </template>
        </data-table>
      </template>

    </v-layout>
  </v-container>
</template>

<script>
import DataTable from "../components/common/dataTable/DataTable";
import Filters from "../components/common/dataTable/filters/Filters";

export default {
  name: "NotificationLogs",
  components: {Filters, DataTable},
  data() {
    return {
      apiurl: 'notification-logs',
      search: {
        placeholder: 'Search by booking id, name, username ...',
        searchAble: true
      },
      sortOrder: [
        {
          field: 'updated_at',
          sortField: 'updated_at',
          direction: 'desc'
        }
      ],
      loaded: false,
      filters: null,
    }
  },
  created() {
    this.fetchFilters();
  },
  methods: {
    fetchFilters() {
      this.$http.get(this.url + 'filters/notification-logs').then((res) => {
        if (res.data) {
          this.filters = res.data;
          this.loaded = true;
        } else {
          alert('there is some problem');
        }
      });
    },
  }
}
</script>
